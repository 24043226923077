import { createSlice } from "@reduxjs/toolkit";
import { Token, User, UserAuthData } from "../component/auth/model";

const emptyAuthInfosSelection: UserAuthData = {
	token: {} as Token,
	user: {} as User
}


const authSelection = createSlice (
	{
		name: 'authSelection',
		initialState: emptyAuthInfosSelection,
		reducers : {
			updateToken: (state, action) => {
				state.token = action.payload
			},
			updateUser: (state, action) => {
				state.user = action.payload
			}
		}
	}
)


export const {
	updateToken,
	updateUser,
} = authSelection.actions
export default authSelection.reducer