import React, { useState } from "react";
import { useLazyGetUserInfosQuery, useLoginMutation, useLogoutMutation } from "../../service/usersApi";
import { Credential, Token, User } from "./model";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateToken, updateUser } from "../../store/authSlice";
import { useAppSelector } from "../../store/store";

interface AuthContextType {
    token: Token | undefined;
    userInfos: User,
    isSignIn: boolean,
    errorMessage: string | undefined,
    signin: (credential: Credential, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
  }
  


  let AuthContext = React.createContext<AuthContextType>(null!);
  
  export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [login] = useLoginMutation();
    const [getUserInfo] = useLazyGetUserInfosQuery()
    const [logout] = useLogoutMutation()
    const [userInfos, setUserInfos] = useState<any>(null);
    const [token, setToken] = useState<Token | undefined>()
    const [isSignIn, setIsSignIn] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const dispatch = useDispatch()

  
    let signin = async (credential: Credential, callback: VoidFunction) => {
        setIsSignIn(true)
        //setErrorMessage(undefined)
        try {
            //Token
          let token : Token | undefined
          await login({
            username: credential.username,
            password: credential.password,
          }).unwrap()
          .then((userToken) => {
              token = userToken
              dispatch(updateToken(token))
              localStorage.setItem("token", JSON.stringify(token))
          })
          .catch((error) => setErrorMessage(error.data.error))
          
            //User Infos
        if (token) {
          let userInfo: User | undefined = undefined;
          await getUserInfo(undefined, false)
            .unwrap()
            .then((user) => {
                          userInfo = user
                          dispatch(updateUser(userInfo))
                          localStorage.setItem("user", JSON.stringify(userInfo))
                          setToken(token)
                          setUserInfos(userInfo)
                          callback()
                      })
            .catch(error => setErrorMessage(error.data.error))
          }
        } catch(error) {
            setErrorMessage("Échec de la connexion")
        } finally {
            setIsSignIn(false)
        }
    }
  
    let signout = (callback: VoidFunction) => {
      dispatch(updateToken(undefined))
      dispatch(updateUser(undefined))
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      logout({})
      setToken(undefined)
      setUserInfos(undefined)
      callback()
    };
  
    let value = { token, errorMessage, isSignIn, userInfos, signin, signout };
  
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }
  
  export const useAuth = () => {
    return React.useContext(AuthContext);
  }
  
  export const AuthStatus = () => {
    let auth = useAuth();
    const user = useAppSelector(state => state.authSlice.user)
    const token = useAppSelector(state => state.authSlice.token)
    let navigate = useNavigate();
  
    if (!token) {
      return <p>You are not logged in.</p>;
    }
  
    return (
      <p className="text-center">
        Bienvenue {user.firstName + " "+user.lastName}!{" "}
        <button
          onClick={() => {
            auth.signout(() => navigate("/"));
          }}
        >
          Sign out
        </button>
      </p>
    );
  }
  
  export const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const dispatch = useDispatch()
    let auth = useAuth();
    let location = useLocation();

    if (!auth.token) {

        try {
            const token = localStorage.getItem("token")
            const user = localStorage.getItem("user")
            if(token && user) {
                const tokenInfos : Token = JSON.parse(token)
                const userInfos: User = JSON.parse(user)
                dispatch(updateToken(tokenInfos))
                dispatch(updateUser(userInfos))
                return children;
            }
        } catch(error) {}

      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
  }