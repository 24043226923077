import { ca } from "date-fns/locale"
import { Bus, Status } from "./service/type"


export const removeDuplicate = (data: string[]) : string[] => {
    return Array.from(new Set(data))
}

export const getBusStatusSeverity = (status: Status) : 'success' | 'info' | 'warning' | 'danger' | null | undefined => {
    var severity : 'success' | 'info' | 'warning' | 'danger' | null | undefined = undefined
    switch(status.code) {
        case "IN_SERVICE" :
            severity = "success"
            break
        case "MAINTENANCE" :
            severity = "warning"
            break
        case "PENDING":
            severity = "info"
            break
        case "OUT_OF_SERVICE" :
            severity = "danger"
            break
        default :
            severity = "info"
    }
    return severity
}