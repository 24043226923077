import { Button } from 'primereact/button';
import React, { useRef, useState } from 'react'
import { DialogProps } from '../../propsType';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from "primereact/floatlabel";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Nullable } from 'primereact/ts-helpers';
import { Calendar } from 'primereact/calendar';
import { formatDate } from '../../DateUtils';
import { useAddBusMutation } from '../../service/travelMgrApi';

export const BusForm = ({visible, setVisible } : DialogProps) => {

    const [createBus] = useAddBusMutation();
    const [isCreatingBus, setCreatingBus] = useState(false)

    const [busNumber, setBusNumber] = useState<string>('')
    const [registrationNumber, setRegistrationNumber] = useState<string>('')
    const [model, setModel] = useState<string>('')
    const [capacity, setCapacity] = useState<Nullable<number>>()
    const [lastMaintenance, setLastMaintenance] = useState<Nullable<Date>>()
    const [nextMaintenance, setNextMaintenance] = useState<Nullable<Date>>()

    const toast = useRef<Toast>(null);


    const onBusCreation = async () => {
        setCreatingBus(true)
		try {
			const createdStation = await createBus({
                busNumber : busNumber,
                capacity: capacity as number,
                registrationNumber: registrationNumber,
                model: model,
                lastMaintenance: formatDate(lastMaintenance as Date),
                nextMaintenance: formatDate(nextMaintenance as Date),
			}).unwrap();

		} catch (error) {
            setCreatingBus(false)
            setVisible(false)
			throw error
		}
        //setSelectedStationName("")
        setCreatingBus(false)
        setVisible(false)
	};

    const footerContent = (
        <div>
            <Button label="Annuler" size='small' icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Valider" size='small' icon="pi pi-check" onClick={onBusCreation} autoFocus />
        </div>
    );



    return (

        <Dialog
            header="Enregistrer un bus" 
            visible={visible} 
            onHide={() => {if (!visible) return; setVisible(false)}} 
            footer={footerContent}
            className='w-12 md:w-8 xl:w-6 2xl:w-4 zoomin animation-duration-500'
            pt={{
                content: {
                    className: "pb-0"
                }
            }}
            >
                <p className="m-0">
                    <div className='card border-0 p-0'>
                        {isCreatingBus && 
                            <div className="card text-center" style={{minHeight: '50px'}}>
                                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                        }
                        
                        <div className='my-4 flex flex-column gap-6'>
                            <div className="formgrid grid">
                                <div className="field col-12 lg:col-6 mb-5">
                                    <FloatLabel>
                                        <InputText id="busNumber" value={busNumber} onChange={(e) => setBusNumber(e.target.value)} className='w-full'/>
                                        <label htmlFor="busNumber">Numéro du bus</label>
                                    </FloatLabel>
                                </div>

                                <div className="field col-12 lg:col-6 mb-5">
                                    <FloatLabel>
                                        <InputText id="model" value={model} onChange={(e) => setModel(e.target.value)} className='w-full'/>
                                        <label htmlFor="model">Modèle</label>
                                    </FloatLabel>
                                </div>
                                
                                <div className="field col-12 lg:col-6 mb-5">
                                    <FloatLabel>
                                        <InputText id="immatriculation" value={registrationNumber} onChange={(e) => setRegistrationNumber(e.target.value)} className='w-full'/>
                                        <label htmlFor="immatriculation">Immatriculation</label>
                                    </FloatLabel>
                                </div>
                                <div className="field col-12 lg:col-6 mb-5">
                                    <FloatLabel>
                                        <InputNumber id="stationName" value={capacity} onValueChange={(e: InputNumberValueChangeEvent) => setCapacity(e.value)} mode="decimal" showButtons min={0} max={100} className='w-full'/>
                                        <label htmlFor="stationName">Capacité</label>
                                    </FloatLabel>
                                </div>
                                <div className="field col-12 lg:col-6 mb-5">
                                    <FloatLabel>
                                        <Calendar
                                            id='lastMaintenance'
                                            value={lastMaintenance} 
                                            onChange={(e) => setLastMaintenance(e.value)} 
                                            dateFormat="dd/mm/yy"
                                            locale='fr' 
                                            selectionMode="single"
                                            //maxDate={new Date()}
                                            className='w-full'
                                            />
                                        <label htmlFor="stationName">Date du dernier contrôle</label>
                                    </FloatLabel>
                                </div>
                                <div className="field col-12 lg:col-6 mb-5">
                                    <FloatLabel>
                                        <Calendar
                                            id="nextMaintenance"
                                            value={nextMaintenance} 
                                            onChange={(e) => setNextMaintenance(e.value)} 
                                            dateFormat="dd/mm/yy"
                                            locale='fr'
                                            selectionMode="single" 
                                            className='w-full'
                                            />
                                            <label htmlFor="stationName">Date du prochain contrôle</label>
                                    </FloatLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                </p>
                <Toast ref={toast} />
        </Dialog>

        
    )
}
