import { Card } from "primereact/card"
import { CardHeader } from "../../component/shared/CardHeader"


export const SettingsHomePage = () => {

    return <Card title={<CardHeader title="Paramètres" />} className='shadow-none' style={{backgroundColor: "transparent"}}  pt={{
        content: {
          className: "p-0"
        }}}>

        <div className="flex flex-column align-items-center align-content-center justify-content-center" style={{minHeight : "80vh"}} >
            <i className="pi pi-cog font-bold text-5xl"/> 
            <div className="font-bold text-5xl">En cours de developpement ...</div>
            <div className="text-xl">revenez plus tard</div>
        </div>

    </Card>

}