import { Button } from 'primereact/button'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import React, { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from './AuthProvider'
import { Credential } from './model'
import { Message } from 'primereact/message'

export const LoginPage = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  let from = location.state?.from?.pathname || "/";

  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const onSignIn = useCallback((credential: Credential) => {
    try {
      setLoading(true)
      console.log(loading)
      auth.signin(credential, () => {
        navigate(from, { replace: true });
      })
    } catch(error){
      console.log(error)
    } finally {
      setLoading(false)
      console.log(loading)
    }
  }, [])

  return (
    <div className='flex'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 800" className="fixed left-0 top-0 min-h-screen min-w-screen" preserveAspectRatio="none"><rect fill="var(--primary-500)" width="1600" height="800"></rect><path fill="var(--primary-400)" d="M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z"></path><path fill="var(--primary-300)" d="M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z"></path><path fill="var(--primary-200)" d="M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z"></path><path fill="var(--primary-100)" d="M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z"></path></svg>
          <div className='flex w-full align-items-center justify-content-center z-1' style={{"height": "100vh"}}>
            <div className='card col-11 sm:col-8 md:col-6 lg:col-5 xl:col-4 xl:w-30rem p-4 md:p-6 border-3 shadow-8'>
                <p className='font-bold text-900 text-xl'>Connexion</p>
                {auth.errorMessage && <Message severity="error" text={auth.errorMessage} className='mb-4 w-full' /> }
                <div className='flex flex-column gap-4'>
                  <IconField iconPosition="left" className='w-full'>
                      <InputIcon className="pi pi-phone"/>
                      <InputText placeholder="Numéro de téléphone"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        className='w-full' pt={{
                        root: {
                          style: {
                            "paddingTop": ".5rem",
                            "paddingBottom": ".5rem"
                          }
                        }
                      }}/>
                  </IconField>

                  <IconField iconPosition="left" className='w-full'>
                      <InputIcon className="pi pi-lock"/>
                      <InputText placeholder="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='w-full' pt={{
                        root: {
                          style: {
                            "paddingTop": ".5rem",
                            "paddingBottom": ".5rem"
                          }
                        }
                      }}/>
                  </IconField>

                  <div className='text-right'>
                    <Button link label="Mot de passe oublié ?" pt={{
                      root: {
                        className: 'px-0'
                      }
                    }}/>
                  </div>


                  <Button 
                    size='small' type="button" 
                    label='Connexion'
                    loading={auth.isSignIn}
                    onClick={(e) => onSignIn({username: userName, password: password})}
                    pt={{
                      root: {
                        style: {
                          "paddingTop": ".5rem",
                          "paddingBottom": ".5rem"
                        }
                      }
                    }}/>

                </div>
            </div>

          </div>
    </div>
  )
}

