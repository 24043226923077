import { Button } from 'primereact/button';
import React, { useCallback, useRef, useState } from 'react'
import { DialogProps } from '../../propsType';
import { useCreatePlanningMutation, useGetAllRouteQuery, useGetFareClassQuery, useGetOccurencesQuery} from '../../service/travelMgrApi';
import { Occurence, Price, Route } from '../../service/type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { faChild, faPersonWalkingLuggage } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import { LightRouteDisplayTemplate, RouteDisplayTemplate } from '../route/RouteDisplayTemplate';
import { OccurenceDisplayTemplate } from './occurence/OccurenceDisplayTemplate';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from "primereact/inputmask";
import { removeDuplicate } from '../../utils';
import { formatDate, formatDateToTime } from '../../DateUtils';


export const ScheduleForm = ({visible, setVisible } : DialogProps) => {

    const [isCreatingPlanning, setCreatingPlanning] = useState(false)
    const { data: routesData, error: routeQError, isLoading: routeQLoading, status: routeQStatus, isError: routeQIsError, isFetching: routeQIsFetching } = useGetAllRouteQuery()
    const { data: occurencesData, error: occurencesQError, isLoading: occurencesQLoading, status: occurencesQStatus, isError: occurencesQIsError, isFetching: occurencesQIsFetching } = useGetOccurencesQuery()
    //const { data: fareClassData, error: fareClassError, isLoading: fareClassLoading, status: fareClassStatus, isError: fareClassIsError, isFetching: fareClassIsFetching } = useGetFareClassQuery()
    const [createPlanningApi] = useCreatePlanningMutation();
    
    const op = useRef<OverlayPanel>(null);

    const [selectedRoute, setSelectedRoute] = useState<Route>()
    const [selectedOccurence, setSelectedOccurence] = useState<Occurence>()
    const [currentDate, setCurrentDate] = useState<Nullable<Date>>()
    const [startDate, setStartDate] = useState<Nullable<Date>>(new Date())
    const [endDate, setEndDate] = useState<Nullable<Date>>()
    const [time, setTime] = useState<Nullable<Date>>(null);
    const [duration, setDuration] = useState<Nullable<string>>()
    const [childPrice, setChildPrice] = useState<Nullable<number>>()
    const [adultePrice, setAdultePrice] = useState<Nullable<number>>()

    
    const fromNullableStringsToStrings = useCallback((dates: Nullable<String[]>) : string[] => {
        return removeDuplicate((dates as string[]).map(date => date))
    }, [time])


    const routeItemTemplate = (option: Route) => {
        return (
            <RouteDisplayTemplate route={option} titleFontSize={1} contentFontSize={1} />
        );
    };

    const routeSelectedItemTemplate = (route: Route) => {
        
        return (
            route ? <LightRouteDisplayTemplate route={route} titleFontSize={1} contentFontSize={2} /> : <div>Choisissez un itinéraire</div>
        );
    };

    const occurenceItemTemplate = (occurence: Occurence) => {
        return (
            <OccurenceDisplayTemplate occurence={occurence}/>
        );
    }

    const selectedOccurenceItemTemplate = (occurence: Occurence) => {
        return (
            occurence ? <OccurenceDisplayTemplate occurence={occurence}/> : <div>Choisissez une fréquence</div>
        );
    }

    const onPlanningCreation = async () => {
        setCreatingPlanning(true)

		try {
            const prices : Price[] = [{fareClass: {code: "CHILD", label: "Enfant"}, amount: childPrice as number}, {fareClass: {code: "ADULT", label: "Adulte"}, amount: adultePrice as number}]
			if(selectedRoute && selectedOccurence) {
                const createPlanning = await createPlanningApi({
                    routeId:        selectedRoute?.id,
                    occurrenceCode: selectedOccurence?.code,
                    startDate:      formatDate(startDate as Date),
                    endDate:        formatDate(endDate as Date),
                    time:           formatDateToTime(time as Date),
                    duration:       duration as string,
                    prices:         prices
                    
                })
            } else {
                console.log("Error")
            }

		} catch (error) {
            setCreatingPlanning(false)
            setVisible(false)
			throw error
		}
        setCreatingPlanning(false)
        setVisible(false)
	}

    const footerContent = (
        <div className='pr-4'>
            <Button label="Annuler" size='small' icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Valider" size='small' icon="pi pi-check" autoFocus onClick={onPlanningCreation}/>
        </div>
    )


    return (

        <Dialog
            header="Créer un planning de voyage" 
            visible={visible} 
            onHide={() => {if (!visible) return; setVisible(false); }}
            footer={footerContent}
            className='w-12 md:w-10 xl:w-8 2xl:w-6 zoomin animation-duration-500'
            pt={{
                content: {
                    className: "pb-0"
                }
            }}>
                <p className="m-0 card border-0 p-0">
                    <div className='card border-0 p-1'>
                        {isCreatingPlanning && 
                            <div className="card text-center" style={{minHeight: '50px'}}>
                                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                        }
                        
                        <div className='mx-4 mt-4 mb-0'>
                            <div className="formgrid grid">
                                <div className="field col-12 lg:col-6 mb-5">
                                    <div className='mb-1'>
                                        <label htmlFor="route">Itinéraire</label>
                                    </div>
                                    <Dropdown loading={routeQLoading || routeQIsFetching} value={selectedRoute} id='route' itemTemplate={routeItemTemplate} valueTemplate={routeSelectedItemTemplate}  scrollHeight="400px" onChange={(e) => setSelectedRoute(e.value)} options={routesData} optionLabel="name" 
                                        placeholder="Choisissez un itinéraire" className="w-full" />
                                </div>

                                <div className="field col-12 lg:col-6 mb-5">
                                    <div className='mb-1'>
                                        <label htmlFor="route">Fréquence</label>
                                    </div>
                                    <Dropdown loading={occurencesQLoading || occurencesQIsFetching} value={selectedOccurence} itemTemplate={occurenceItemTemplate} valueTemplate={selectedOccurenceItemTemplate} onChange={(e) => setSelectedOccurence(e.value)} options={occurencesData} optionLabel="label" 
                                        placeholder="Choisissez une fréquence" className="w-full" />
                                </div>

                                <div className="field col-12 lg:col-6 mb-5">
                                    <div className='mb-1'>
                                        <label htmlFor="route">Date Début</label>
                                    </div>
                                    <Calendar 
                                        value={startDate} 
                                        onChange={(e) => setStartDate(e.value)} 
                                        dateFormat="dd/mm/yy"
                                        minDate={new Date()}
                                        locale='fr' 
                                        selectionMode="single" 
                                        className='w-full'
                                        />
                                </div>

                                <div className="field col-12 lg:col-6 mb-5">
                                    <div className='mb-1'>
                                        <label htmlFor="route">Date fin</label>
                                    </div>
                                    <Calendar 
                                        value={endDate} 
                                        onChange={(e) => setEndDate(e.value)}
                                        dateFormat="dd/mm/yy"
                                        minDate={new Date()}
                                        locale='fr' 
                                        selectionMode="single" 
                                        className='w-full'
                                        />
                                </div>

                                <div className="field col-12 lg:col-6 mb-5">
                                    <div className='mb-1'>
                                        <label htmlFor="route">Horaire</label>
                                    </div>
                                    <div className=''>
                                        <Calendar value={time} 
                                            onChange={(e) => setTime(e.value)}
                                            timeOnly
                                            onFocus={(e) => {if(op != null && op.current != null) op.current.toggle(e)}} 
                                            className="w-full"
                                            pt={{container: {className: 'w-full'}}} />
                                    </div>
                                </div>

                                <div className="field col-12 lg:col-6 mb-5">
                                    <div className='mb-1'>
                                        <label htmlFor="duration">Durée du voyage</label>
                                    </div>
                                    <InputMask
                                        id="duration"
                                        mask="99h 99min"
                                        placeholder="06h 30min"
                                        value={duration}
                                        onChange={(e) => setDuration(e.target.value)}
                                        className='w-full'
                                        />
                                </div>

                                <div className="field col-12 lg:col-6 mb-5">
                                    <div className='mb-1'>
                                        <label htmlFor="route">Prix enfant</label>
                                    </div>

                                    <div className="p-inputgroup flex-1">
                                        <span className="p-inputgroup-addon bg-primary">
                                            <FontAwesomeIcon icon={faChild} size='xl' />
                                        </span>
                                        <InputNumber 
                                        inputId="childPrice" 
                                        value={childPrice} onValueChange={(e) => setChildPrice(e.value)} 
                                        mode="currency" 
                                        currency="XOF" 
                                        locale="fr" 
                                        className='w-full'
                                        />
                                    </div>
                                    

                                </div>

                                <div className="field col-12 lg:col-6 mb-5">
                                    <div className='mb-1'>
                                        <label htmlFor="route">Prix adulte</label>
                                    </div>

                                    <div className="p-inputgroup flex-1">
                                        <span className="p-inputgroup-addon bg-primary">
                                            <FontAwesomeIcon icon={faPersonWalkingLuggage} className='' size='xl' />
                                        </span>
                                        <InputNumber 
                                        inputId="adultePrice" 
                                        value={adultePrice} onValueChange={(e) => setAdultePrice(e.value)} 
                                        mode="currency" 
                                        currency="XOF" 
                                        locale="fr"
                                        className='w-full'
                                        />
                                    </div>

                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </p>
        </Dialog>

        
    )
}
