import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
//import { Credential, User } from "../dto/data.dto";
import { RootState } from "../store/store";
import { Credential, User } from "../component/auth/model";
//import { isTokenActive } from "../utils/Utils";


// Define a service using a base URL and expected endpoints
export const usersApi = createApi({
	reducerPath: "usersApi",
	//baseQuery: fetchBaseQuery({ baseUrl: 'https://dev-fasodoctors.com:8444/bonjourdoc/api/' }),
	baseQuery: fetchBaseQuery({
		//baseUrl: 'http://192.168.1.115:8082/user-management/api/',
		baseUrl: "https://auth.fasocarexpress.com/api/fce/auth/",
		timeout: 2000,
		prepareHeaders: (headers, { getState }) => {
			const authData = (getState() as RootState).authSlice
			if (authData && authData.token && authData.token.accesToken) {
				headers.set("authorization", `Bearer ${authData.token.accesToken}`);
				return headers;
			}
		},
	}),


	endpoints: (builder) => ({
		getUserInfo: builder.mutation<User, void>({
			query: () => {
				return {
					url: "users/userinfo",
					method: "GET",
				};
			},

		}),


		getUserInfos: builder.query<User, void>({
			query: () => {
				return {
					url: "users/userinfo",
					method: "GET",
				};
			},
			/*keepUnusedDataFor: 0.00001,
			forceRefetch({ currentArg, previousArg }) {
				return true
			},*/
		}),
		login: builder.mutation({
			query: (param: Credential) => {
				return {
					url: "auth/login",
					method: "POST",
					body: param,
				};
			},
		}),
		/*
		signIn: builder.mutation({
			query: (param: PatientAccountInfo) => {
				return {
					url: "users/patient",
					method: "POST",
					body: param,
				};
			},
		}),
		*/

		logout: builder.mutation({
			query: () => {
				return {
					url: "auth/logout",
					method: "POST",
				};
			},
		}),


		confirmAccount: builder.mutation({
			query: (param: { username: string; confirmationCode: string }) => {
				return {
					url: "users/confirm",
					method: "PUT",
					params: { ...param },
				};
			},
		})
	}),
});


export const {
	useLoginMutation,
	useGetUserInfoMutation,
	//useSignInMutation,
	useConfirmAccountMutation,
	useGetUserInfosQuery,
	useLazyGetUserInfosQuery,
	useLogoutMutation,
} = usersApi;
