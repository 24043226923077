import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";  //theme
//import "primereact/resources/themes/lara-dark-cyan/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import StationHomePage from './app/page/station/StationHomePage';
import { ScheduleHomePage } from './app/page/schedule/ScheduleHomePage';
import { RouteHomePage } from './app/page/route/RouteHomePage';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ScheduleDetails } from './app/page/schedule/ScheduleDetails';
import { DashboardHomePage } from './app/page/dashboard/DashboardHomePage';
import { LoginPage } from './app/component/auth/LoginPage';
import { HomePage } from './app/page/HomePage';
import { RequireAuth } from './app/component/auth/AuthProvider';
import { TravelHomePage } from './app/page/travel/TravelHomePage';
import { FleetHomePage } from './app/page/fleet/FleetHomePage';
import { FinanceHomePage } from './app/page/finance/FinanceHomePage';
import { BillHomePage } from './app/page/bill/BillHomePage';
import { CustomerHomePage } from './app/page/customer/CustomerHomePage';
import { SurveyHomePage } from './app/page/survey/SurveyHomePage';
import { StatisticsHomePage } from './app/page/statistics/StatisticsHomePage';
import { SettingsHomePage } from './app/page/settings/SettingsHomePage';
import { UsersHomePage } from './app/page/users/UsersHomePage';
import { AuditHomePage } from './app/page/audit/AuditHomePage';
import { TicketHomePage } from './app/page/ticket/TicketHomePage';


function App() {

  return (
    <PrimeReactProvider>
      <div >
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/' element={<RequireAuth><HomePage /></RequireAuth>}>
            <Route path='' element={<Navigate to="dashboard" />} />
            <Route path='dashboard' element={<DashboardHomePage />} />
            <Route path='planning' element={<ScheduleHomePage />} />
            <Route path='travel' element={<TravelHomePage />}/>
            <Route path='planning/:planningId' element={<ScheduleDetails />} />
            <Route path='station' element={<StationHomePage />} />
            <Route path='route' element={<RouteHomePage />} />
            <Route path='ticket' element={<TicketHomePage />} />
            <Route path='fleet' element={<FleetHomePage />} />
            <Route path='finance' element={<FinanceHomePage />} />
            <Route path='bill' element={<BillHomePage />} />
            <Route path='customer' element={<CustomerHomePage />} />
            <Route path='survey' element={<SurveyHomePage />} />
            <Route path='statistics' element={<StatisticsHomePage />} />
            <Route path='settings' element={<SettingsHomePage />} />
            <Route path='users' element={<UsersHomePage />} />
            <Route path='audit' element={<AuditHomePage />} />
          </Route>
        </Routes>
      </div>
    </PrimeReactProvider>
  )
}

export default App;
