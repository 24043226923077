import { Card } from "primereact/card"
import { CardHeader } from "../../component/shared/CardHeader"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { ProgressSpinner } from "primereact/progressspinner"
import { useChangeBusStatusMutation, useGetBusStatusesQuery, useGetCompanyBusesQuery } from "../../service/travelMgrApi"
import { Button } from "primereact/button"
import { useCallback, useRef, useState } from "react"
import { BusForm } from "./BusForm"
import { Bus, Status } from "../../service/type"
import { formatDateString } from "../../DateUtils"
import { Badge } from "primereact/badge"
import { getBusStatusSeverity } from "../../utils"
import { OverlayPanel } from "primereact/overlaypanel"


export const FleetHomePage = () => {

    const op = useRef<OverlayPanel>(null);


    const { data: busesQData, error: busesQError, isLoading: busesQLoading, status: busesQStatus, isError: busesQIsError, isFetching: busesQIsFetching } = useGetCompanyBusesQuery()
    const { data: busStatusData, error: busStatusQError, isLoading: busStatusQLoading, status: busStatusQStatus, isError: busStatusQIsError, isFetching: busStatusQIsFetching } = useGetBusStatusesQuery()
    const [changeBusStatusApi] = useChangeBusStatusMutation()

    const [visible, setVisible] = useState<boolean>(false)
    const [tableSselectedBus, setTableSelectedBus] = useState<any>()
    const [selectedBus, setSelectedBus] = useState<Bus>()

    const busStatus = useCallback(() : Status[] | undefined => {
        return busStatusData?.filter(status => selectedBus?.status.code != status.code)
    }, [selectedBus])

    const noBusMessageComponent = () => {
        return <div className='flex flex-column align-items-center'>
          <div className='my-2'>
            Aucun bus enregistré
          </div>
          <div className='my-2 flex gap-3'>
            <Button label="Enregistrer un bus" size='small' icon="pi pi-map-marker" onClick={() => setVisible(true)}/>
          </div>
        </div>
      }
    
    const lastMaintenanceBody = (bus: Bus) => {
        return <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis">{formatDateString(bus.lastMaintenance)}</div>
    }

    const statusBody = (bus: Bus) => {
        return <div className="flex justify-content-center align-content-center">
            <Badge value={bus.status.label} severity={getBusStatusSeverity(bus.status)}
                size="normal" className="px-4 justify-content-center white-space-nowrap text-overflow-ellipsis"
                onClick={(e) => {
                    setSelectedBus(bus)
                    if(op && op.current) {op.current.toggle(e)}
                }}/>
        </div>
    }

    const nextMaintenanceBody = (bus: Bus) => {
        return <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis">{formatDateString(bus.nextMaintenance)}</div>
    }

    const actionTemplate = (bus: Bus) => {
        return <div className='flex gap-2'>
            <Button size='small' icon="pi pi-pencil" text  rounded aria-label="Filter" severity='contrast' />
            <Button size='small' icon="pi pi-trash" text  rounded aria-label="Filter" severity='danger' /*onClick={(e) => onStationDeletion(station.id)}*/ />
        </div>
    }

    return <Card title={<CardHeader title="Gestion de la flotte" buttonLabel="Bus" onButtonClick={() => setVisible(true)} />} className='shadow-none' >

        <div>

        <OverlayPanel ref={op}>
            <div className="flex flex-column gap-3">
                {busStatus()?.map(status => 
                    <Badge 
                        onClick={(e) => {
                            if(selectedBus) {
                                const result = changeBusStatusApi({busId: selectedBus.id, statusCode: status.code})
                                if(op && op.current) {
                                    op.current.hide()
                                }
                            }
                        }}
                        className="cursor-pointer hover:border-gray-300 border-1" 
                        value={status.label} severity={getBusStatusSeverity(status)} />
                ) }
            </div>
        </OverlayPanel>

        {(busesQLoading || busesQIsFetching) &&
            <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
        }

        {busesQData && 
            <DataTable 
                value={busesQData} size='normal' rows={6} paginator={true}
                alwaysShowPaginator={false} dataKey="id" emptyMessage={noBusMessageComponent}
                selectionMode="single" selection={tableSselectedBus} tableStyle={{ minWidth: '50rem'}}
                onSelectionChange={(e) => setTableSelectedBus(e.value as Bus)}
                pt={{
                    wrapper: {
                        className: "common-scroll"
                    }
                }}>
                <Column field="busNumber" header="Nº bus" sortable />
                <Column field="status.code" header="Status" body={statusBody} sortable />
                <Column field="registrationNumber" header="Nº immatriculation" sortable />
                <Column field="model" header="Modèle" sortable />
                <Column field="capacity" header="Capacité" sortable />
                <Column field="lastMaintenance" header="Dernier contrôle" body={lastMaintenanceBody} sortable />
                <Column field="nextMaintenance" header="Prochain contrôle" body={nextMaintenanceBody} sortable />
                <Column header="Actions" style={{ flex: '0 0 4rem' }} body={actionTemplate} bodyStyle={{ width: '3rem' }}></Column>
            </DataTable>
        }

        <BusForm visible={visible} setVisible={setVisible} />

        </div>

    </Card>

}