import { configureStore } from '@reduxjs/toolkit'
import { travelMgrApi } from '../service/travelMgrApi'
import { setupListeners } from '@reduxjs/toolkit/query'
import { usersApi } from '../service/usersApi'
import authSlice from './authSlice'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

export const store = configureStore({
  reducer: {
    [travelMgrApi.reducerPath]: travelMgrApi.reducer,
    [usersApi.reducerPath] : usersApi.reducer,
    authSlice: authSlice,

  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(travelMgrApi.middleware)
    .concat(usersApi.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)