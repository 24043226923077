import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { ReactNode, useState } from "react"
import { RouteForm } from "./RouteForm"
import { useDeleteRouteMutation, useGetAllRouteQuery } from "../../service/travelMgrApi"
import { RouteDiagram } from "./RouteDiagram"
import { Route, Station } from "../../service/type"
import { DataView } from "primereact/dataview"
import { classNames } from "primereact/utils"
import { InputText } from "primereact/inputtext"
import { ProgressSpinner } from "primereact/progressspinner"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import { FullRouteDisplayTemplate, RouteDisplayTemplate } from "./RouteDisplayTemplate"


export const RouteHomePage = () => {

    const { data, error, isLoading, status, isError, isFetching } = useGetAllRouteQuery()
    const [deleteRouteApi] = useDeleteRouteMutation()

    const [visible, setVisible] = useState<boolean>(false)
    const [filterValue, setFilterValue] = useState<string>()


    const stationListFromRoute = (route: Route) : Station[] => {
        const stationss : Station[] = []
        stationss.push(route.fromStation)
        route.stops.forEach(route => stationss.push(route.stop))
        stationss.push(route.toStation)
        return stationss
    }



    const routeCardTitle = () => {
        return <div className='flex justify-content-between flex-wrap'>
            <div>Gestion des itinéraires</div>
            <div className='flex align-items-center justify-content-center gap-4'>
                {header()}
                <Button size='small' type="button" className='mt-1' icon="pi pi-plus" label='Itinéraire' onClick={() => setVisible(true)} pt={{
              root: {
                style: {
                  "padding-top": ".5rem",
                  "padding-bottom": ".5rem"
                }
              }}}/>
            </div>
        </div>
    }


    const itemTemplate = (route: Route, index: number) => {
            return <div className="col-12 bg-white my-5 border-1 border-gray-200 border-round-xl p-0" >
                <div className="flex flex-row">
                    <div className="bg-gray-200 px-1 font-bold align-content-center border-round-left-xl bg-primary"></div>
                    <div className="flex-grow-1 overflow-x-auto common-scroll">
                        <div className={classNames('flex flex-column xl:align-items-start p-4 py-4')}>
                            <div className="text-2xl font-bold text-900 text-primary">{route.name}</div>
                            <div className="flex-grow-1  justify-content-center w-full zoomin animation-duration-1000">
                                {/*<RouteDiagram stations={stationListFromRoute(route)} animated={false}/>*/}
                                <FullRouteDisplayTemplate route={route} contentFontSize={2} titleFontSize={1} hideTitle={true} displayDuration={true} />
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-200 font-bold align-content-center border-round-right-xl">
                    <div className='flex flex-column gap-2 border-round-right-xl'>
                        <Button size='small' icon="pi pi-pencil" text  rounded  aria-label="Filter"  severity='contrast' />
                        <Button size='small' icon="pi pi-trash" text  rounded aria-label="Filter" severity='contrast' onClick={() => onStationDeletion(route.id)} />
                    </div>
                    </div>
                </div>
            </div>
                
        }
    
    const header = () => {
        return <div className="flex flex-row-reverse">
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText 
                    placeholder="Search" type="text" 
                    className="p-inputtext-sm w-8rem sm:w-auto" 
                    value={filterValue} onChange={(e) => setFilterValue(e.target.value)} 
                    pt={{
                        root: {
                          style: {
                            "borderRadius": "2rem",
                            "paddingTop": ".5rem",
                            "paddingBottom": ".5rem"
                          }
                        }
                      }}/>
            </IconField>
        </div>
    }

    const emptyRoute = () => {
        return <div className='flex flex-column align-items-center justify-content-center' style={{height: "80vh"}}>
          <div className='my-2'>
            Aucun itinéraire
          </div>
          <div className='my-2 flex gap-3'>
          <Button size='small' type="button" className='mt-1' icon="pi pi-plus" label='Itinéraire' onClick={() => setVisible(true)} pt={{
              root: {
                style: {
                  "padding-top": ".5rem",
                  "padding-bottom": ".5rem"
                }
              }}}/>
          </div>
        </div>
      }

    const listTemplate = (routes: Route[]) : ReactNode[] => {
        if (!routes || routes.length === 0) return []

        let list = routes.map((route, index) => {
            return itemTemplate(route, index)
        })
        return list
    }

    const filterRoute = (routes : Route[]) : Route[] => {
        if(!filterValue || filterValue.trim() == '') {
            return routes
        }
        const result = routes.filter(route => route.name.toLowerCase().includes(filterValue.toLowerCase()))
        console.log(result)
        return result
    }

    const onStationDeletion = async (routeId : number) => {
        try {
            const result = await deleteRouteApi(routeId).unwrap()
        } catch (error) {
            throw error
        }
    }


    return(
        <Card title={routeCardTitle} className='shadow-none' style={{backgroundColor: "transparent"}} pt={{
            content: {
              className: "p-0"
            }}}>

            {(isLoading || isFetching) &&
                <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
                    <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            }
            {
            data && 
                <DataView 
                    value={filterRoute(data)} 
                    listTemplate={listTemplate} 
                    dataKey="id" /*header={header()}*/
                    paginator={true} rows={3} 
                    alwaysShowPaginator={false} 
                    emptyMessage="Aucun itineraire à afficher"
                    
                    pt={{
                        content: {
                            style: {
                                "backgroundColor": "transparent"
                            }
                        },
                        paginator: {
                            root: {
                                className: "border-round-xl bg-red-500 text-3xl p-5",
                                style: {
                                    "backgroundColor": "red"
                                }
                            }
                        }
                    }}
                     />
            }

            {
            data && data.length ==0 &&
            emptyRoute()
            }

            <RouteForm visible={visible} setVisible={setVisible} />
        </Card>
    )
}