import { Card } from "primereact/card"
import { CardHeader } from "../../component/shared/CardHeader"
import { Dropdown } from "primereact/dropdown"
import { ReactNode, useCallback, useEffect, useState } from "react"
import { TravelWithDetails, SeatFillRate, Route, Travel } from "../../service/type"
import { FullRouteDisplayTemplate, LightRouteDisplayTemplate, RouteDisplayTemplate } from "../route/RouteDisplayTemplate"
import { useGetAllRouteQuery, useGetDetailedTravelsInPlanningQuery, useGetDetailedTravelsQuery } from "../../service/travelMgrApi"
import { Calendar } from "primereact/calendar"
import { Nullable } from "primereact/ts-helpers"
import { ProgressSpinner } from "primereact/progressspinner"
import { atEndOfDay, atStartOfDay, formatDateString, formatTimeString, parseDateString } from "../../DateUtils"
import { Badge } from "primereact/badge"
import { DataView } from "primereact/dataview"
import { Divider } from "primereact/divider"
import { classNames } from "primereact/utils"
import { addDays, isAfter, isBefore, isEqual } from "date-fns"
import { MeterGroup } from "primereact/metergroup"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { PriceComponent } from "../schedule/Price"
import { Tag } from "primereact/tag"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBus, faChild, faPersonWalkingLuggage, faUserTie } from "@fortawesome/free-solid-svg-icons"


export const TravelHomePage = () => {

    const { data: routesData, error: routeQError, isLoading: routeQLoading, status: routeQStatus, isError: routeQIsError, isFetching: routeQIsFetching } = useGetAllRouteQuery()
    const { data: travelQData, error: travelQError, isLoading: travelQLoading, status: travelQStatus, isError: travelQIsError, isFetching: travelQIsFetching } = useGetDetailedTravelsQuery()

    const [selectedRoute, setSelectedRoute] = useState<Route>()
    const [selectedDetailedTravel, setSelectedDetailedTravel] = useState<TravelWithDetails>()

    const startDate = new Date()
    const endDate = addDays(new Date(), 7)
    const [dateRange, setDateRange] = useState<Nullable<(Date | null)[]>>([startDate, endDate])

    useEffect(() => {
        setSelectedDetailedTravel(travelQData && travelQData.length > 0 ? [... travelQData].sort((a, b) => (a.travel.startDate+a.travel.startTime).localeCompare(b.travel.startDate+b.travel.startTime))[0]  : undefined)
    }, [travelQData])

    const passengerData = [
        {
          "firstName": "Fatoumata",
          "lastName": "Koulibaly",
          "documentType": "PASSPORT",
          "documentNumber": "BP1234567",
          "category": "ADULTE",
          "birthDate": "1990-01-15",
          "cost": 45000,
          "paymentMethod": "EN LIGNE",
          "onlinePaymentMethod": "MOOV MONEY",
          "reservationAgent": "Sanfo Mariam"
        },
        {
          "firstName": "Ibrahim",
          "lastName": "Ouédraogo",
          "documentType": "CNIB",
          "documentNumber": "CNIB789654123",
          "category": "ENFANT",
          "birthDate": "2020-05-10",
          "cost": 20000,
          "paymentMethod": "GUICHET",
          "onlinePaymentMethod": "LIQUIDE",
          "reservationAgent": "Sanou Madeleine"
        },
        {
          "firstName": "Aminata",
          "lastName": "Sangaré",
          "documentType": "PASSPORT",
          "documentNumber": "BP9876543",
          "category": "ADULTE",
          "birthDate": "1985-09-27",
          "cost": 35000,
          "paymentMethod": "EN LIGNE",
          "onlinePaymentMethod": "ORANGE MONEY",
          "reservationAgent": "Application Mobile"
        },
        {
          "firstName": "Moussa",
          "lastName": "Diallo",
          "documentType": "CNIB",
          "documentNumber": "CNIB123456789",
          "category": "ADULTE",
          "birthDate": "1975-03-05",
          "cost": 50000,
          "paymentMethod": "GUICHET",
          "onlinePaymentMethod": "LIQUIDE",
          "reservationAgent": "OUATTARA Minata"
        },
        {
          "firstName": "Awa",
          "lastName": "Traoré",
          "documentType": "PASSPORT",
          "documentNumber": "BP6547891",
          "category": "ENFANT",
          "birthDate": "2021-08-21",
          "cost": 15000,
          "paymentMethod": "EN LIGNE",
          "onlinePaymentMethod": "LIQUIDE",
          "reservationAgent": "Sanfo Mariam"
        }
      ]
      
      

    const fillRateData = (fillRate: SeatFillRate) => {
        return { label: fillRate.fareClass.label, color: fillRate.fareClass.code === 'CHILD' ? '#fbbf24' : '#34d399', value: fillRate.rate }
    }

    const fillRatesData = (fillRates: SeatFillRate[]) => {
        return fillRates.map(fillRate => fillRateData(fillRate))
    }

    const dataToDisplay = useCallback(() => {
        return travelQData ? 
            [...travelQData]
                .filter(detailedTravel => selectedRoute ? selectedRoute.id === detailedTravel.travel.route.id : true)
                .filter(detailedTravel => dateRange && dateRange.length == 2 ? 
                    (isAfter(parseDateString(detailedTravel.travel.startDate), atStartOfDay(dateRange[0] as Date)) || isEqual(parseDateString(detailedTravel.travel.startDate), atStartOfDay(dateRange[0] as Date))) 
                    && (isBefore(parseDateString(detailedTravel.travel.startDate), atEndOfDay(dateRange[1] as Date)) || isEqual(parseDateString(detailedTravel.travel.startDate), atEndOfDay(dateRange[1] as Date)))
                    :true)
                .sort((a, b) => (a.travel.startDate+a.travel.startTime).localeCompare(b.travel.startDate+b.travel.startTime)) 
            : []
    }, [travelQData, dateRange, selectedRoute])

    const routeItemTemplate = (option: Route) => {
        return (
            <RouteDisplayTemplate route={option} titleFontSize={1} contentFontSize={1} />
        );
    }




    const loader = <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s" />
        </div>
    
    const startDateBody = (rowData: Travel) => {
        return <div>
            <p className="white-space-nowrap overflow-hidden text-overflow-ellipsis text-primary text-lg font-bold">{formatDateString(rowData.startDate)}</p>
            </div>
    }
    

    const startTimeBody = (rowData: Travel) => {
        return <div className="block">
            <div className="text-center">{formatTimeString(rowData.startTime)}  {formatTimeString(rowData.endTime)}</div>
            <Badge value={rowData.duration} severity="info" size="normal" className="flex justify-content-center"/>
            </div>
    }

    const routeSelectedItemTemplate = (route: Route) => {
        
        return (
            route ? <LightRouteDisplayTemplate route={route} titleFontSize={1} contentFontSize={2} /> : <div>Choisissez un itinéraire</div>
        );
    }

    const travelDataViewHeader = () => {
        return <div className="flex gap-3">
            <Dropdown 
                loading={routeQLoading || routeQIsFetching} 
                value={selectedRoute} id='route' itemTemplate={routeItemTemplate} 
                valueTemplate={routeSelectedItemTemplate}  scrollHeight="400px" 
                onChange={(e) => setSelectedRoute(e.value)} options={routesData} optionLabel="name" 
                placeholder="Choisissez un itinéraire" className="w-full" />

            <Calendar
                value={dateRange} 
                onChange={(e) => setDateRange(e.value)} 
                dateFormat="dd/mm/yy"
                minDate={new Date()}
                locale='fr' 
                selectionMode="range" 
                readOnlyInput hideOnRangeSelection
                className='w-full'
                />
        </div>
    }
    


    const itemTemplate = (detailedTravel: TravelWithDetails, index: number) => {
        return <div className={classNames("hover:bg-primary-50", {"bg-primary-50": detailedTravel.travel.id === selectedDetailedTravel?.travel.id})} onClick={() => setSelectedDetailedTravel(detailedTravel)} >
                <div className="flex cursor-pointer border-round-xl py-3" >
                    <div className="flex-grow-1 overflow-hidden text-overflow-ellipsis">
                        <FullRouteDisplayTemplate route={detailedTravel.travel.route} titleFontSize={2} contentFontSize={1}/>
                    </div>
                    <div className="mr-3">
                        <div>{startDateBody(detailedTravel.travel)}</div>
                        <div>{startTimeBody(detailedTravel.travel)}</div>
                    </div>
                </div>
                <Divider pt={{
                        root: {
                            className: "m-0"
                        }
                    }}/>
            </div>
    }

    const listTemplate = (detailedTravels: TravelWithDetails[]) : ReactNode[] => {
        if (!detailedTravels || detailedTravels.length === 0) return [<div></div>];

        let list = detailedTravels.map((detailedTravel, index) => {
            return itemTemplate(detailedTravel, index)
        });

        return list;
    }


    const fillingRateBody = (detailedTravel: TravelWithDetails) => {
        return <div>
            <MeterGroup values={fillRatesData(detailedTravel.seatFillRates)} />
            </div>
    }


    return <Card title={<CardHeader title="Gestion des voyages" />} className='shadow-none' style={{backgroundColor: "transparent"}}  pt={{
        content: {
          //className: "p-0"
        }}}>

        <div className="grid">
            <div className="col-12 xl:col-7">
                <div className="card zoomin animation-duration-500 mx-2 p-3 h-full">
                    <DataView 
                        value={dataToDisplay()} 
                        listTemplate={listTemplate}
                        loading={travelQLoading || travelQIsFetching} loadingIcon={loader}
                        header= {travelDataViewHeader()}
                        paginator 
                        rows={3} />
                </div>
            </div>

            
            {
                selectedDetailedTravel && <div className="col-12 xl:col-5 fadeinright animation-duration-300">

                <div className="flex flex-column gap-3 h-full">
                    <div className="card w-full mb-0 p-4">
                        <div className="font-light text-xl pb-2">Prix</div>
                        <div className="flex gap-3 flex-wrap">

                            {selectedDetailedTravel.travel.prices.map(price => 
                                <div className="flex flex-grow-1 justify-content-center align-content-center align-items-center">
                                    <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer white-space-nowrap">
                                        <div className="mr-2"><FontAwesomeIcon icon={price.fareClass.code == "CHILD" ? faChild : faPersonWalkingLuggage} className='' size='lg' /></div>
                                        <div className="text-lg mr-2">{price.fareClass.code == "CHILD" ? "Enfant" : "Adulte"}</div>
                                        <div className="text-lg font-bold text-primary flex-grow-1 text-right"><span>{price.amount}</span> <small>fcfa</small></div>
                                    </div>
                                </div>
                            )}
                            </div> 
                        
                    </div>
                    <div className="card w-full mb-0 flex-grow-1 p-4">
                        <div className="flex align-items-center gap-5 flex-wrap">
                            <div className="flex-grow-1">
                                <div className="font-light text-xl pb-5">Billeterie</div>
                                {selectedDetailedTravel && fillingRateBody(selectedDetailedTravel)}
                            </div>
                            <div className="flex flex-row gap-3 flex-wrap w-full">
                                <Badge 
                                    value={
                                        <span className="flex gap-2 align-items-center">
                                            <span className="white-space-nowrap">{selectedDetailedTravel.travel.sellAllowed? "VENTE OUVERTE" : "VENTE FERMÉE"}</span>
                                        </span>} 
                                    severity={!selectedDetailedTravel.travel.sellAllowed ? 'danger': 'success'} 
                                    size="normal" 
                                    className="flex justify-content-center py-3 cursor-pointer flex-grow-1"/>

                                
                                <Badge 
                                value={
                                    <span className="flex gap-2 align-items-center">
                                        <span className="white-space-nowrap">{selectedDetailedTravel.travel.ticketAvailable? 'BILLET DISPONIBLE' : "BILLET COMPLET"}</span>
                                    </span>} 
                                severity={!selectedDetailedTravel.travel.ticketAvailable ? 'danger': 'success'} 
                                size="normal" 
                                className="flex justify-content-center py-3 cursor-pointer flex-grow-1"/>
                            </div>
                        </div>
                    </div>
                    <div className="card w-full h-full mb-0 flex-grow-1 p-4">
                        <div className="flex flex-column">
                            <div className="font-light font-normal text-xl pb-2">Bus</div>
                            <div className="grid mb-3">
                                <div className="col-12 flex justify-content-center align-content-center align-items-center">
                                    <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer">
                                        <div className="mr-2"><FontAwesomeIcon icon={faBus} className='' size='lg' /></div>
                                        <div className="text-lg font-bold">BUS</div>
                                        <div className="text-lg font-bold text-primary flex-grow-1 text-right">103</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-4 flex-wrap">
                            <div className="flex flex-column flex-grow-1 gap-2">
                                <div className="font-light font-normal text-xl pb-2">Chauffeurs</div>

                                    <div className="col-12 p-0 flex justify-content-center align-content-center align-items-center">
                                        <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer">
                                            <div className="mr-2"><FontAwesomeIcon icon={faUserTie} className='' size='lg' /></div>
                                            <div className="text-lg">1</div>
                                            <div className="text-lg font-bold text-primary flex-grow-1 text-right">Inoussa ZONGO</div>
                                        </div>
                                    </div>

                                    <div className="col-12 p-0 flex justify-content-center align-content-center align-items-center">
                                        <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer">
                                            <div className="mr-2"><FontAwesomeIcon icon={faUserTie} className='' size='lg' /></div>
                                            <div className="text-lg">2</div>
                                            <div className="text-lg font-bold text-primary flex-grow-1 text-right">Arouna SANKARA</div>
                                        </div>
                                    </div>
                            </div>


                            <div className="flex flex-column flex-grow-1 gap-2">
                                <div className="font-light font-normal text-xl pb-2">Hôtesses</div>

                                    <div className="col-12 p-0 flex justify-content-center align-content-center align-items-center">
                                        <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer">
                                            <div className="mr-2"><FontAwesomeIcon icon={faUserTie} className='' size='lg' /></div>
                                            <div className="text-lg">1</div>
                                            <div className="text-lg font-bold text-primary flex-grow-1 text-right">Adeline NASSÉ</div>
                                        </div>
                                    </div>

                                    <div className="col-12 p-0 flex justify-content-center align-content-center align-items-center">
                                        <div className="w-full border-round flex p-2 border-1 border-gray-200 hover:bg-gray-100 cursor-pointer">
                                            <div className="mr-2"><FontAwesomeIcon icon={faUserTie} className='' size='lg' /></div>
                                            <div className="text-lg">2</div>
                                            <div className="text-lg font-bold text-primary flex-grow-1 text-right">Roseline TAMBOURA</div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
            }

        </div>

        <div className="card my-2">
            <div className="font-light font-semibold text-xl pb-5">Passagers</div>

            <DataTable 
                value={passengerData} size="normal" className="common-scroll"
                selectionMode="single"
                loading={travelQLoading || travelQIsFetching} loadingIcon={loader}
                tableStyle={{ minWidth: '50rem' }} 
                pt={{
                wrapper: {
                    className: "common-scroll"
                }
            }}>
                <Column field="lastName" header="Nom"/>
                <Column field="firstName" header="Prénom" />
                <Column field="documentType" header="Document d'identité" />
                <Column field="documentNumber" header="Nº document d'identité" />
                <Column field="category" header="Catégory" />
                <Column field="birthDate" header="Date de naissance" className="white-space-nowrap"/>
                <Column field="paymentMethod" header="Mode de paiment" className="white-space-nowrap" />
                <Column field="cost" header="Prix" />
                <Column field="onlinePaymentMethod" header="Methode de PAIEMENT" className="white-space-nowrap" />
                <Column field="reservationAgent" header="Guichetière" className="white-space-nowrap" />
            </DataTable>
        </div>

    </Card>

}