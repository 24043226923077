import { useEffect, useRef, useState } from 'react';
import './../../App.css';
import { addLocale, locale, PrimeReactProvider } from 'primereact/api';

import "primereact/resources/themes/lara-light-cyan/theme.css";  //theme

//import "primereact/resources/themes/lara-dark-cyan/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import { Avatar } from 'primereact/avatar';
import { Toast } from 'primereact/toast';
import frLocal from '../local/fr.json'
import { classNames } from 'primereact/utils';
import { Menu } from 'primereact/menu';
import { Divider } from 'primereact/divider';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { AuthStatus, useAuth } from '../component/auth/AuthProvider';
import { json } from 'stream/consumers';
import { useAppSelector } from '../store/store';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import { custumFormatDateTime, custumFormatDateTimeRelative } from '../DateUtils';
import { addDays, addHours, subHours } from 'date-fns';
import { Sidebar } from 'primereact/sidebar';



export const HomePage = () => {

  locale('en');
  addLocale('fr', frLocal)

  const navigate = useNavigate();
  const location = useLocation()
  const [selectdMenu, setSelectedMenu] = useState<string>('')
  const user = useAppSelector(state => state.authSlice.user)
  const auth = useAuth()
  const op = useRef<OverlayPanel>(null);


  useEffect(() => {
    setSelectedMenu(location.pathname)
  })
  
  const css = `
        .my-chip-margin {
          margin-top: 0rem;
          margin-bottom: 0rem;
          }
    `;

    const cardCustumCss = `
    .my-card-padding {
      padding: 0rem 0;
      }
    `;


  const toast = useRef<Toast>(null);
  const [sideBarVisible, setSideBarVisible] = useState(true)
  const [userParamVisible, setUserParamVisible] = useState(false)

  const itemRenderer = (item: MenuItem, option: MenuItemOptions) => (
    <div className='p-menuitem-content shadow-none' 
      onClick={(e) => {
        setSelectedMenu(item.data)
        navigate(item.data)
      }}
      >
        <a className={classNames("flex align-items-center p-menuitem-link border-round-sm hover:bg-primary-100", {"bg-primary-100 text-primary-600": selectdMenu.startsWith(item.data +"")})}>
            <span>
              {typeof item.icon =='string' ? <span className={item.icon}/> : item.icon}
            </span>
            <span className="mx-2 text-sm">{item.label}</span>
            
        </a>
    </div>
)

  const getMenuItem = (path: string) : MenuItem | undefined => {
    const menuItems = items.filter(item => path.startsWith(item.data +""))
    return menuItems.length >0 ? menuItems[0] : undefined
    
  }


  const items: MenuItem[] = [
    {
      label: 'Tableau de board',
      data: "/dashboard",
      icon: 'pi pi-chart-line',
      template : itemRenderer
    },
    {
      label: 'Planning',
      data: "/planning",
      icon: 'pi pi-calendar',
      template : itemRenderer
    },
    {
      label: 'Voyage',
      data: "/travel",
      icon: 'pi pi-map',
      template : itemRenderer
    },
    {
      label: 'Gares',
      data: '/station',
      icon: 'pi pi-map-marker',
      template : itemRenderer
    },
    {
      label: "Itinéraire",
      data: '/route',
      icon: 'pi pi-arrow-right-arrow-left',
      template : itemRenderer
    },
    {
      label: 'Billeterie',
      data: '/ticket',
      icon: 'pi pi-ticket',
      template : itemRenderer
    },
    {
        label: 'Flotte',
        data: '/fleet',
        icon: 'pi pi-car',
        template : itemRenderer
    },
    {
      label: 'Finances',
      data: '/finance',
      icon: 'pi pi-euro',
      template : itemRenderer
    },
    {
      label: 'Factures',
      data: '/bill',
      icon: 'pi pi-receipt',
      template : itemRenderer
    },
    {
      label: 'Clients',
      data: '/customer',
      icon: 'pi pi-users',
      template : itemRenderer
    },
    {
      label: 'Sondages',
      data: '/survey',
      icon: 'pi pi-megaphone',
      template : itemRenderer
    },
    {
      label: 'Statistiques',
      data: '/statistics',
      icon: 'pi pi-chart-bar',
      template : itemRenderer
    },
    {
      label: "Paramètres",
      data: '/settings',
      icon: 'pi pi-cog',
      template : itemRenderer
    },
    {
      label: 'Audit',
      data: '/audit',
      icon: 'pi pi-file-edit',
      template : itemRenderer
    },
    {
      label: 'Utilisateurs',
      data: '/users',
      icon: 'pi pi-user',
      template : itemRenderer
    }
  ]


  const Option = ({title, subTitle, icon, severity}: {title: string, subTitle: String, icon: string, severity?: 'primary' | 'error' | 'warning'}) => {
    return <div className={
      classNames('flex flex-row align-items-center gap-3 border-1 cursor-pointer border-round p-2', 
      {"border-orange-100 text-orange-400 hover:bg-orange-50": severity === 'warning'},
      {"border-primary-100 text-primary hover:bg-primary-50": severity == undefined || severity === 'primary'},
      {"border-red-100 text-red-400 hover:bg-red-100": severity === 'error'},
      )}>
    <div><i className={icon} style={{ fontSize: '1.5rem' }}></i></div>
    <div>
      <div className='font-bold'>{title}</div>
      <small className={classNames(
        {"text-orange-400" : severity === 'warning'},
        {"text-gray-400" : severity == undefined || severity === 'primary'}
        )}>{subTitle}</small>
    </div>
  </div>
  }


  const logo = <img alt="logo" src="./images/logo.png" height="80" className="mr-2"></img>

  const menuBarEnd = (
    <div className="flex align-items-center gap-3">
        <Button icon="pi pi-cog" rounded text />
        {user && <div className='flex gap-3 align-items-center cursor-pointer' onClick={(e) => {
          //if(op.current) op.current.toggle(e)
          setUserParamVisible(true)
        }}>
          <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" className="p-overlay-badge">
            <Badge value="3"></Badge>
          </Avatar>
          <div>{user.firstName} {user.lastName}</div>
        </div>
        }

          <Sidebar visible={userParamVisible} showCloseIcon={false} position="right" onHide={() => setUserParamVisible(false)} pt={{
            content : {
              className: "common-scroll"
            }
          }}>
            <div className='flex flex-column h-full' >
              <div className='flex flex-column gap-4 flex-grow-1'>
                <div>
                  <div className='text-700 font-bold'>Bienvenue</div>
                  <div>{user.firstName} {user.lastName}</div>
                </div>

                <Option title='Profil' subTitle="Consulter ou modifier votre profil" icon='pi pi-user' />
                <Option title="Paramètres" subTitle="Modifier vos paramètres" icon='pi pi-cog' />

                <div className='flex flex-column gap-4'>
                  <div className='flex flex-column'>
                    <div className='text-700 font-bold'>Notifications</div>
                    <small className='text-gray-400'>Vous avez 3 notifications</small>
                  </div>

                  <Option title={"Entretien du bus n° 25"} subTitle={"prevu "+custumFormatDateTimeRelative(addHours(new Date(), -24))} icon='pi pi-wrench' severity='error' />
                  <Option title={"Entretien du bus n° 33"} subTitle={"prevu "+custumFormatDateTimeRelative(addHours(new Date(), 24))} icon='pi pi-wrench' severity='warning'/>
                  <Option title={"Entretien du bus n° 053"} subTitle={"prevu "+custumFormatDateTimeRelative(addDays(new Date(), 10))} icon='pi pi-wrench'/>

                </div>
              </div>

              <div className='py-4'>
                <div className='flex flex-row justify-content-center'>
                  <Button 
                    label="Deconnexion" 
                    severity="danger" 
                    icon="pi pi-power-off" text size='small'
                    onClick={() => {
                      auth.signout(() => navigate("/"))
                    }}/>
                </div>

                <div className='text-center'>
                  <small>Dernière connexion : {custumFormatDateTimeRelative(subHours(new Date(), 33))}</small>
                </div>
              </div>
            </div>
          </Sidebar>

    </div>
)


return (
      <div className={classNames("layout-container layout-light layout-colorscheme-menu", {"layout-static": sideBarVisible}, {"layout-static-inactive": !sideBarVisible})} style={{"backgroundColor": "#eff3f8"}}>
        <div className='layout-sidebar'>
            <div className='sidebar-header text-primary' style={{fontSize: "1.5rem"}}>
              {logo}
            </div>
            <div>
              <Divider />
              <div className='overflow-y-auto common-scroll' >
              <Menu model={items} style={{"height": "80vh"}} className="w-full overflow-y-auto common-scroll" pt={{
                root: {
                  className: "border-0",
                  style: {
                    "backgroundColor": "transparent",
                    "borderColor": "transparent",
                  }
                }
              }}/>
              </div>
            </div>
        </div>


        <div className='layout-content-wrapper' style={{"backgroundColor": "#eff3f8"}}>
          <div className='flex align-items-center mb-2'>
            <div 
              className='flex hover:bg-primary align-content-center align-items-center justify-content-center border-circle' 
              style={{width : "2.25rem", height: "2.25rem"}}
              onClick={(e)=> setSideBarVisible(!sideBarVisible)}
              >
              <i className="pi pi-bars cursor-pointer" style={{ fontSize: '1.25rem' }}></i>
            </div>
            <p className='ml-3 font-bold text-500'>App&nbsp;&nbsp; / &nbsp;&nbsp;{getMenuItem(selectdMenu)?.label}</p>
            <div className="flex-grow-1">
                
            </div>
            {menuBarEnd}
          </div>


          <div>
              <Outlet />
          </div>
        </div>


        <style>{cardCustumCss}</style>
        <style>{css}</style>
        <Toast ref={toast} />
      </div>
  );
}